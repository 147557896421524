const shpwrite = require('shp-write'),
  clone = require('clone'),
  geojson2dsv = require('geojson2dsv'),
  topojson = require('topojson-server'),
  saveAs = require('file-saver'),
  tokml = require('@placemarkio/tokml'),
  geojsonNormalize = require('@mapbox/geojson-normalize'),
  wellknown = require('wellknown');

const flash = require('./flash'),
  zoomextent = require('../lib/zoomextent'),
  readFile = require('../lib/readfile'),
  meta = require('../lib/meta.js'),
  directions = require('./directions.js'),
  overpass = require('./overpass.js');

/**
 * This module provides the file picking & status bar above the map interface.
 * It dispatches to source implementations that interface with specific
 * sources, like GitHub.
 */
module.exports = function fileBar(context) {
  const shpSupport = typeof ArrayBuffer !== 'undefined';

  const exportFormats = [
    {
      title: 'GeoJSON',
      action: downloadGeoJSON
    },
    {
      title: 'TopoJSON',
      action: downloadTopo
    },
    {
      title: 'CSV',
      action: downloadDSV
    },
    {
      title: 'KML',
      action: downloadKML
    },
    {
      title: 'WKT',
      action: downloadWKT
    },
    { title: 'Directions', action: downloadDirections }
  ];

  if (shpSupport) {
    exportFormats.push({
      title: 'Shapefile',
      action: downloadShp
    });
  }

  function bar(selection) {
    const actions = [
      {
        title: 'Import',
        alt: 'CSV, GTFS, KML, GPX, and other filetypes',
        action: blindImport
      },
      {
        title: 'Export',
        children: exportFormats
      },
      {
        title: 'New',
        action: function () {
          window.open(
            window.location.origin + window.location.pathname + '#new'
          );
        }
      },
      {
        title: 'Tools',
        action: function () {},
        children: [
          {
            title: 'Add raster tile layer',
            alt: 'Add a custom tile layer',
            action: function () {
              const layerURL = prompt(
                'Layer URL\ne.g. https://stamen-tiles-b.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg'
              );
              if (layerURL === null) return;
              const layerName = prompt('Layer name');
              if (layerName === null) return;
              meta.adduserlayer(context, layerURL, layerName);
            }
          },
          {
            title: 'Zoom to features',
            alt: 'Zoom to the extent of all features',
            action: function () {
              meta.zoomextent(context);
            }
          },
          {
            title: 'Get directions',
            alt: 'Get directions',
            action: function () {
              if (!directions.isRouteMode()) {
                if (confirm('Start route directions?')) {
                  directions.startRoute(context);
                }
              } else {
                if (directions.isRouteStarted()) {
                  const profile = prompt(
                    'Start route directions (highway, offroad or trail)?',
                    'highway'
                  );
                  if (
                    profile === 'highway' ||
                    profile === 'offroad' ||
                    profile === 'trail'
                  ) {
                    let mboxProfile = '';
                    switch (profile) {
                      case 'highway':
                        mboxProfile = 'driving';
                        break;
                      case 'offroad':
                        mboxProfile = 'cycling';
                        break;
                      case 'trail':
                        mboxProfile = 'walking';
                        break;
                      default:
                        mboxProfile = 'driving';
                    }
                    directions.stopRoute(context, mboxProfile);
                  }
                } else {
                  if (confirm('Stop route directions?')) {
                    directions.stopRoute(context);
                  }
                }
              }
            }
          },
          {
            title: 'Get offroad trails',
            alt: 'Get offroad trails from OpenStreetMap',
            action: function () {
              if (context.map.getZoom() < 10) {
                alert('Zoom in to reduce map area');
              } else {
                overpass.getTrails(context);
              }
            }
          },

          {
            title: 'Clear',
            alt: 'Delete all features from the map',
            action: function () {
              if (
                confirm(
                  'Are you sure you want to delete all features from this map?'
                )
              ) {
                meta.clear(context);
                directions.clear(context);
              }
            }
          }
        ]
      },
      {
        title: 'Help',
        action: function () {},
        children: [
          {
            title: 'Open help',
            alt: 'Open help',
            action: function () {
              window.open('help.html', 'NavOffroad Help');
            }
          },
          {
            title: 'Watch demo video',
            alt: 'Watch demo video',
            action: function () {
              window.open('youtube.html', 'NavOffroad Video');
            }
          },
          {
            title: 'Get the iOS app',
            alt: 'Get the iOS app',
            action: function () {
              const qrCodeImage = document.getElementById('qrcodeImage');
              if (qrCodeImage.style.display === 'none') {
                qrCodeImage.style.display = 'block';
              } else {
                qrCodeImage.style.display = 'none';
              }
            }
          }
        ]
      }
    ];

    const items = selection
      .append('div')
      .attr('class', 'inline')
      .selectAll('div.item')
      .data(actions)
      .enter()
      .append('div')
      .attr('class', 'item');

    items
      .append('a')
      .attr('class', 'parent')
      .on('click', function (d) {
        if (d.action) d.action.apply(this, d);
      })
      .text((d) => {
        return ' ' + d.title;
      });

    items.each(function (d) {
      if (!d.children) return;
      d3.select(this)
        .append('div')
        .attr('class', 'children')
        .call(submenu(d.children));
    });

    function submenu(children) {
      return function (selection) {
        selection
          .selectAll('a')
          .data(children)
          .enter()
          .append('a')
          .attr('title', (d) => {
            if (
              d.title === 'File' ||
              d.title === 'Add map layer' ||
              d.title === 'Zoom to features' ||
              d.title === 'Clear' ||
              d.title === 'Flatten Multi Features'
            )
              return d.alt;
          })
          .text((d) => {
            return d.title;
          })
          .on('click', function (d) {
            d.action.apply(this, d);
          });
      };
    }

    function blindImport() {
      const put = d3
        .select('body')
        .append('input')
        .attr('type', 'file')
        .attr(
          'accept',
          '.txt,.xml,.json,.geojson,.topojson,.kml,.gpx,.csv,application/json,application/xml'
        )
        .style('visibility', 'hidden')
        .style('position', 'absolute')
        .style('height', '0')
        .on('change', function () {
          const files = this.files;
          if (!(files && files[0])) return;
          readFile.readAsText(files[0], (err, text) => {
            readFile.readFile(files[0], text, onImport);
            if (files[0].path) {
              context.data.set({
                path: files[0].path
              });
            }
          });
          put.remove();
        });
      put.node().click();
    }

    function onImport(err, gj, warning) {
      gj = geojsonNormalize(gj);
      if (gj) {
        context.data.mergeFeatures(gj.features);
        if (warning) {
          flash(context.container, warning.message);
        } else {
          flash(
            context.container,
            'Imported ' + gj.features.length + ' features.'
          ).classed('success', 'true');
        }
        zoomextent(context);
      }
    }

    d3.select(document).call(
      d3.keybinding('file_bar').on('⌘+o', () => {
        blindImport();
        d3.event.preventDefault();
      })
    );
  }

  function downloadTopo() {
    const content = JSON.stringify(
      topojson.topology(
        {
          collection: clone(context.data.get('map'))
        },
        { 'property-transform': allProperties }
      )
    );

    saveAs(
      new Blob([content], {
        type: 'application/json;charset=utf-8'
      }),
      'map.topojson'
    );
  }

  function downloadGeoJSON() {
    if (d3.event) d3.event.preventDefault();
    const content = JSON.stringify(context.data.get('map'));
    const meta = context.data.get('meta');
    saveAs(
      new Blob([content], {
        type: 'application/json;charset=utf-8'
      }),
      (meta && meta.name) || 'map.geojson'
    );
  }

  function downloadDSV() {
    if (d3.event) d3.event.preventDefault();
    const content = geojson2dsv(context.data.get('map'));
    saveAs(
      new Blob([content], {
        type: 'text/plain;charset=utf-8'
      }),
      'points.csv'
    );
  }

  function downloadKML() {
    if (d3.event) d3.event.preventDefault();
    const content = tokml.toKML(context.data.get('map'));
    saveAs(
      new Blob([content], {
        type: 'application/xml;charset=utf-8'
      }),
      'map.kml'
    );
  }

  function downloadShp() {
    if (d3.event) d3.event.preventDefault();
    d3.select('.map').classed('loading', true);
    try {
      shpwrite.download(context.data.get('map'));
    } finally {
      d3.select('.map').classed('loading', false);
    }
  }

  function downloadWKT() {
    if (d3.event) d3.event.preventDefault();
    const features = context.data.get('map').features;
    if (features.length === 0) return;
    const content = features.map(wellknown.stringify).join('\n');
    saveAs(
      new Blob([content], {
        type: 'text/plain;charset=utf-8'
      }),
      'map.wkt'
    );
  }

  function downloadDirections() {
    if (directions.isRouteMode()) {
      const geojsonData = directions.saveRoute(context);
      if (geojsonData != null) {
        saveAs(
          new Blob([JSON.stringify(geojsonData)], {
            type: 'application/json;charset=utf-8'
          }),
          'route.geojson'
        );
      }
    }
  }

  function allProperties(properties, key, value) {
    properties[key] = value;
    return true;
  }

  return bar;
};
