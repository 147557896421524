// (c) Stephen Denham, 2023

const mapboxgl = require('mapbox-gl');
let coordinates = [];
let markers = [];
let distanceLabel;
let isRouteStopped = false;

function routeMarker(context, id, coords) {
  const label = id === 'routeStart' ? 'Start' : 'End';
  const color = id === 'routeStart' ? '#00f900' : '#ff2600';
  const marker = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: coords
    },
    properties: {
      name: label,
      'marker-color': color,
      'marker-size': 'medium',
      'marker-symbol': 'marker'
    }
  };
  return marker;
}

async function getMatch(context, coordinates, radius) {
  const radiuses = radius.join(';');
  const query = await fetch(
    `https://api.mapbox.com/matching/v5/mapbox/walking/${coordinates}?geometries=geojson&radiuses=${radiuses}&steps=false&access_token=${mapboxgl.accessToken}`,
    {
      method: 'GET'
    }
  );
  const response = await query.json();
  if (response.code !== 'Ok') {
    alert(`${response.message}.\n\n`);
    clear(context);
    return;
  }
  const data = response.matchings[0];
  drawRoute(context, data);
}

async function getDirections(context, start, end, profile) {
  const query = await fetch(
    `https://api.mapbox.com/directions/v5/mapbox/${profile}/${start[0]},${start[1]};${end[0]},${end[1]}?alternatives=false&annotations=distance&geometries=geojson&overview=full&steps=false&access_token=${mapboxgl.accessToken}`,
    {
      method: 'GET'
    }
  );
  const response = await query.json();
  if (response.code !== 'Ok') {
    alert(`${response.message}.\n\n`);
    clear(context);
    return;
  }
  const data = response.routes[0];
  drawRoute(context, data);
}

function drawRoute(context, data) {
  const route = data.geometry.coordinates;
  const distance = (data.distance * 0.000621371).toFixed(2) + ' miles';
  const routeLineString = {
    type: 'Feature',
    properties: {
      name: 'route',
      distance: distance,
      stroke: '#3887be',
      'stroke-opacity': 0.75,
      'stroke-width': 6.0,
      'line-gap-width': 2.0
    },
    geometry: {
      type: 'LineString',
      coordinates: route
    }
  };
  const routeLine = context.map.getSource('route');
  const currentFeatures = routeLine._data.features;
  currentFeatures.push(routeLineString);
  routeLine.setData({
    type: 'FeatureCollection',
    features: currentFeatures
  });
  context.map.addLayer({
    id: 'route',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#3887be',
      'line-width': 6,
      'line-opacity': 0.75,
      'line-gap-width': 2.0
    }
  });
  const end = route[route.length - 1];
  distanceLabel = new mapboxgl.Popup()
    .setLngLat(end)
    .setHTML(distance)
    .addTo(context.map);
}

function startRoute(context) {
  if (context.map.getSource('route') == null) {
    context.map.addSource('route', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });
  }
  const controlButtons = document.getElementsByClassName(
    'mapbox-gl-draw_ctrl-draw-btn'
  );
  for (let i = 0; i < controlButtons.length; i++) {
    controlButtons[i].disabled = true;
  }
  isRouteStopped = false;
}

function stopRoute(context, profile) {
  if (!isRouteStopped) {
    if (coordinates.length === 2) {
      const routeStart = [
        coordinates[0].split(',')[0],
        coordinates[0].split(',')[1]
      ];
      const routeEnd = [
        coordinates[coordinates.length - 1].split(',')[0],
        coordinates[coordinates.length - 1].split(',')[1]
      ];
      getDirections(context, routeStart, routeEnd, profile);
    } else if (coordinates.length > 2) {
      const coords = coordinates.join(';');
      const radius = coordinates.map(() => 50);
      getMatch(context, coords, radius);
      for (let i = 1; i < markers.length - 1; i++) {
        markers[i].remove();
      }
    } else {
      clear(context);
    }
    isRouteStopped = true;
  } else {
    clear(context);
  }
}

function updateRoute(context, event) {
  const coords = event.lngLat.lng + ',' + event.lngLat.lat;
  coordinates.push(coords);

  if (coordinates.length === 1) {
    const marker = new mapboxgl.Marker({ color: '#00f900' })
      .setLngLat([event.lngLat.lng, event.lngLat.lat])
      .addTo(context.map);
    markers.push(marker);
  } else {
    const marker = new mapboxgl.Marker({ color: '#ff2600' })
      .setLngLat([event.lngLat.lng, event.lngLat.lat])
      .addTo(context.map);
    markers.push(marker);
  }
  if (markers.length > 2) {
    for (let i = 1; i < markers.length - 1; i++) {
      const lngLat = markers[i].getLngLat();
      markers[i].remove();
      const marker = new mapboxgl.Marker({ color: '#3887be', scale: 0.5 })
        .setLngLat(lngLat)
        .addTo(context.map);
      markers[i] = marker;
    }
  }
}

function saveRoute(context) {
  if (markers.length >= 2 && isRouteStopped) {
    const routeStart = routeMarker(context, 'routeStart', [
      markers[0].getLngLat().lng,
      markers[0].getLngLat().lat
    ]);
    const routeEnd = routeMarker(context, 'routeEnd', [
      markers[markers.length - 1].getLngLat().lng,
      markers[markers.length - 1].getLngLat().lat
    ]);

    const route = context.map.getSource('route');
    const routeFeatures = route._data.features;
    const distance = routeFeatures[0].properties.distance;
    routeEnd.properties.name = 'End (' + distance + ')';
    routeFeatures.push(routeStart);
    routeFeatures.push(routeEnd);
    return route._data;
  } else {
    return null;
  }
}

function clearMarkers() {
  markers.forEach((marker) => marker.remove());
  markers = [];
}

function clear(context) {
  const controlButtons = document.getElementsByClassName(
    'mapbox-gl-draw_ctrl-draw-btn'
  );
  for (let i = 0; i < controlButtons.length; i++) {
    controlButtons[i].disabled = false;
  }
  coordinates = [];
  clearMarkers();
  isRouteStopped = false;
  if (distanceLabel != null) distanceLabel.remove();
  if (context.map.getLayer('route') != null) context.map.removeLayer('route');
  if (context.map.getSource('route') != null) context.map.removeSource('route');
}

function isRouteStarted() {
  return isRouteStopped ? false : true;
}

function isRouteMode() {
  const controlButtons = document.getElementsByClassName(
    'mapbox-gl-draw_ctrl-draw-btn'
  );
  for (let i = 0; i < controlButtons.length; i++) {
    if (controlButtons[i].disabled) {
      return true;
    } else {
      return false;
    }
  }
}

module.exports = {
  startRoute,
  stopRoute,
  updateRoute,
  saveRoute,
  isRouteMode,
  isRouteStarted,
  clear
};
