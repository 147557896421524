// (c) Stephen Denham, 2024

const osmtogeojson = require('osmtogeojson');
const geojsonNormalize = require('@mapbox/geojson-normalize');
const zoomextent = require('../lib/zoomextent');

async function getTrails(context) {
  const bounds = context.map.getBounds();
  const sw = bounds.getSouthWest();
  const ne = bounds.getNorthEast();

  const formBody = `
	[out:json];
	(
	  way["highway"~"^(service|track|unclassified|road|trail)$"]["surface"~"^(dirt|gravel|ground|unpaved|grass|sand|rocky)$"](${sw.lat},${sw.lng},${ne.lat},${ne.lng});
	);
	out body;
	>;
	out skel qt;
	`;

  fetch('https://overpass-api.de/api/interpreter', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: formBody
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        alert('Failed to download offroad trails');
        return;
      }
    })
    .then((data) => {
      const osmGj = osmtogeojson(data);
      const gj = geojsonNormalize(osmGj);
      if (gj) {
        context.data.mergeFeatures(gj.features);
        zoomextent(context);
        return;
      }
    })
    .catch(() => {
      alert('Failed to download offroad trails');
    });
}

module.exports = {
  getTrails
};
